import Section from '@/Components/Layouts/Section.jsx';
import ImagesStyle from '@/Styles/images.js';
import {Box, Typography} from '@mui/material';
import React, {memo} from 'react';

const YaKIntro = () => {
    return (
        <Section
            sx={(theme) => ({
                paddingLeft: '64px !important',
                [theme.breakpoints.up('lg')]: {
                    width: '75vw',
                    maxWidth: '1920px !important',
                    padding: '64px !important',
                },
            })}
        >
            <Box
                sx={(theme) => ({
                    display: 'flex',
                    flexFlow: 'column wrap',
                    gap: '2rem',
                    width: '100%',

                    [theme.breakpoints.up('md')]: {
                        display: 'grid',
                        rowGap: 0,
                        columnGap: 14,
                        gridTemplateAreas: `"b a"`,
                        gridTemplateColumns: 'calc(60% - 3.5rem) 40%',
                        gridTemplateRows: 'auto 1fr',
                        alignItems: 'start',
                        '& > div:first-of-type': {
                            gridArea: 'a',
                        },
                        '& > *:nth-of-type(2)': {
                            gridArea: 'b',
                        },
                    },
                })}
            >
                <Box
                    sx={(theme) => ({
                        position: 'relative',
                        '&::after': {
                            ...ImagesStyle.background_contain('/theme/ya-k/images/icons/lignes-jaunes.svg'),
                            content: '""',
                            position: 'absolute',
                            top: '1rem',
                            left: 0,
                            transform: 'translateX(-100%)',
                            width: '35px',
                            height: '43px',
                            [theme.breakpoints.up('md')]: {
                                width: '52px',
                                height: '64px',
                            },
                        },
                    })}
                >
                    <Typography variant="fancy_h5">
                        <Box
                            component="span"
                            sx={(theme) => ({
                                color: theme.palette.primary.main,
                            })}
                        >
                            bienvenue
                        </Box>{' '}
                        chez vous
                    </Typography>
                </Box>
                <Typography variant="body1" component="p">
                    <strong>
                        ya'K Construire.com vous offre un savoir-faire global, qui associe construction et agencement
                        intérieur pour créer votre univers.
                    </strong>{' '}
                    Terrain, nombre de chambre, avec ou sans garage, guidé par quelques conseils, réalisez votre futur
                    projet au meilleur prix.
                </Typography>
            </Box>
        </Section>
    );
};

export default memo(YaKIntro);
